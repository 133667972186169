






















































































































import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import { mask } from "vue-the-mask";
import moment from "moment";

type UserData = {
  user: object;
  person: {
    salutation: string;
    salutation_id: string;
  };
  personal_profile: object;
};

@Component({
  components: {
    phone_: () => import("@/app/modules/service/builder/elements/Phone.vue")
  },
  directives: {
    mask
  }
})
export default class ProfileForm extends Vue {
  @Model("input", { type: Object }) readonly userData!: UserData;

  @Emit()
  input() {
    return this.localUserData;
  }

  localUserData!: UserData;

  valid: boolean = true as boolean;
  errors: object = {} as object;

  rules = {
    required: Rules.required,
    birthdate: Rules.birthdate,
    email: Rules.email
  };

  constructor() {
    super();

    this.localUserData = this.userData;
  }

  get salutations() {
    return [
      { long_name: this.$t("PROFILE.SALUTATIONS.NULL"), id: null },
      ...this.$store.getters["profile/salutations"]
    ];
  }

  get storedUserData() {
    return this.$store.getters["profile/data"];
  }

  created() {
    const _profile = this.storedUserData.personal_profile;
    const birthday = _profile ? moment(_profile?.birthday).format("DD.MM.YYYY") : null;

    this.localUserData = {
      user: { ...this.storedUserData.user },
      person: { ...this.storedUserData.person },
      personal_profile: {
        ..._profile,
        birthday
      }
    };

    // TODO: Remove this during the refactoring
    this.localUserData.person.salutation = this.localUserData.person.salutation_id;
  }

  @Watch("localUserData", { deep: true })
  onLocalUserDataChanged() {
    this.input();
  }
}
